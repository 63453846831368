import PhoneInput, { type Value, Country } from 'react-phone-number-input/input';
import React, { forwardRef, useState } from 'react';
import { InputRef } from 'antd';

type PhoneNumberInputProps = {
  onChange?: (value: Value) => void;
  country?: Country;
  value?: string;
  international?: boolean;
  withCountryCallingCode?: boolean;
  defaultCountry?: Country;
  useNationalFormatForDefaultCountryValue?: boolean;
};

const PhoneNumberInput = forwardRef<InputRef, PhoneNumberInputProps>(
  ({
    onChange,
    value: phoneValue = '',
    ...props
  }, ref) => {
    const [value, setValue] = useState<string>(phoneValue);
    const phoneOnChange = onChange || setValue;

    // @ts-ignore
    return (
      <>
        <PhoneInput
          {...props}
          ref={ref}
          value={value}
          onChange={phoneOnChange}
          className='phone-number-input'
        />
      </>
    );
  }
);
export default PhoneNumberInput;
