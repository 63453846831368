import React, { forwardRef, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { flatten, get } from 'lodash';
import { useDynamicList } from 'ahooks';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Button,
  Checkbox,
  Input,
  InputProps,
  InputRef,
  Modal,
  Popconfirm,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { CopyOutlined, SyncOutlined } from '@ant-design/icons';
import { Country } from 'react-phone-number-input/input';
import { reactJoin } from '../../../util/reactJoin';
import ContactLink from '../../../components/Badge/ContactLink';
import { DynamicList } from '../../../util/AHookTypes';
import { DCR_POPOVER_QUERY } from '../../../components/DcrPopOver/DcrPopOver';
import { PersonConsentField_DATA_QUERY } from '../TabViews/PersonConsentFields';
import DraggableTable from '../../../components/DraggableTable/DraggableTable';
import { Locale } from '../../../../localization/LocalizationKeys';
import { getVariableField, personFieldConfig } from './utils/personFieldsUtils';
import {
  ContactFieldCategoriesQuery,
  ContactTypeEnum,
  EntityContactTypesQueryQuery,
  EntityContactTypesQueryQueryVariables,
  EntityTypeEnum,
  SourceEnum,
  useContactFieldCategoriesQuery,
  useUpsertContactValuesMutationMutation,
} from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';
import PhoneNumberInput from '../../../components/Input/PhoneNumberInput';

type FunctionType = () => void;

type TCopyableInput = InputProps &
React.RefAttributes<InputRef> & {
  onCopyContent: (func: FunctionType) => void;
  countryCode: Country;
  onPhoneChange?: (e: string) => void;
  isPhoneNumber?: boolean;
};

const CopyableInput = forwardRef<InputRef, TCopyableInput>(
  (
    {
      onCopyContent,
      countryCode,
      isPhoneNumber = false,
      onPhoneChange,
      ...props
    },
    ref
  ) => {
    const [copy, setCopy] = useState(false);

    useEffect(() => {
      if (copy) {
        setTimeout(() => setCopy(false), 1000);
      }
    }, [copy]);

    const toolTipTitle = copy ? 'Copied' : 'Copy';

    return (
      <>
        {isPhoneNumber ? (
          <div className='phone-number-input-container'>
            <PhoneNumberInput
              value={props.defaultValue as string}
              onChange={onPhoneChange!}
              ref={ref}
              country={countryCode as Country}
              international
              withCountryCallingCode
            />
            <div className='phone-number-input-after-addon'>
              <Tooltip title={toolTipTitle}>
                <CopyOutlined
                  size={12}
                  onClick={() => onCopyContent(() => setCopy(true))}
                />
              </Tooltip>
            </div>
          </div>
        ) : (
          <Input
            {...props}
            ref={ref}
            style={{ margin: '5px 0', padding: '0 5px' }}
            addonAfter={
              <Tooltip title={toolTipTitle}>
                <CopyOutlined
                  size={12}
                  onClick={() => onCopyContent(() => setCopy(true))}
                />
              </Tooltip>
            }
          />
        )}
      </>
    );
  }
);

export const buildEntityContactInformationFieldConfigs = (
  entityType: EntityTypeEnum,
) => personFieldConfig(
  'contactInformation',
  [
    'id',
    'maintainerSourceCode',
    'contactInformation(typeCodes: ["{{keyCode}}"]).entityAffiliationId',
    'contactInformation(typeCodes: ["{{keyCode}}"]).entityType',
    'contactInformation(typeCodes: ["{{keyCode}}"]).contactType.code',
    'contactInformation(typeCodes: ["{{keyCode}}"]).contactType.enum',
    'contactInformation(typeCodes: ["{{keyCode}}"]).values.id',
    'contactInformation(typeCodes: ["{{keyCode}}"]).values.value',
    'contactInformation(typeCodes: ["{{keyCode}}"]).values.consent.id',
    'contactInformation(typeCodes: ["{{keyCode}}"]).values.consent.status',
    'contactInformation(typeCodes: ["{{keyCode}}"]).values.category.code',
    'contactInformation(typeCodes: ["{{keyCode}}"]).values.category.typeEnum',
    'contactInformation(typeCodes: ["{{keyCode}}"]).values.category.heading',
    'contactInformation(typeCodes: ["{{keyCode}}"]).values.category.isMapped', 
  ],
  ({ localization }) => ({
    title: (test) => test.selectedOption?.label,
    additionalTableConfig: {
      width: 200,
    },
    getOptions: [
      localization.formatMessage(Locale.General.Contact_information),
      ({ apolloClient }) => apolloClient.query<EntityContactTypesQueryQuery,
      EntityContactTypesQueryQueryVariables>({
        query: TYPES_QUERY,
        variables: { entityType },
      })
        .then((res) => res.data.contactTypes.nodes),
    ],
    render: (record, options) => {
      if (!options.selectedOption) return ' ';
      const countryCode = get(record, 'countryCode');
      const data = getVariableField(record, options, 'contactInformation');
      const contactField = reactJoin(
        flatten(
          data
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((category: any) => category.values.map((value: any) => (
              <ContactLink
                key={value.id}
                consent={value.consent?.status}
                value={value.value ?? ''}
                type={value.category.typeEnum}
                categoryName={value.category.heading}
                countryCode={countryCode}
              />
            )))
        )
      );

      return (
        <Typography.Paragraph
          style={{ marginBottom: 0 }}
          ellipsis={{
            rows:
                options.isViewingFromPage 
                === TableFieldReturnedRecordPageType.DETAIL_PAGE
                  ? 2
                  : 1,
          }}
        >
          <Tooltip placement="left" title={contactField}>
            {contactField}
          </Tooltip>
        </Typography.Paragraph>
      );
    },
    preserve: true,
    updateView: ({ endEditing, record, options }) => {
      const t = getVariableField(record, options, 'contactInformation');
      if (t.length > 1) throw Error(
        'Contact support and provide timestamp when you saw this error'
      );
      const countryCode = get(record, 'countryCode');
      const information = t[0]!;
      const [visible, setVisible] = useState(true);
      const [stateInputValues, setStateInputValues] = useState<string[]>([]);
      const inputValues = stateInputValues;
        type Value = (typeof record)['contactInformation']['0']['values']['0'];
        const dynamicList: DynamicList<Value> = useDynamicList(
          information.values as Value[]
        );
        const [loading, setLoading] = useState(false);
        const { data } = useContactFieldCategoriesQuery({
          variables: {
            type: information.contactType.enum,
            entityType: information.entityType,
          },
        });
        const [upsert] = useUpsertContactValuesMutationMutation();

        const isApbMaintained = record.maintainerSourceCode === SourceEnum.APB;

        const close = () => {
          setVisible(false);
          endEditing();
        };

        const onSave = () => {
          setLoading(true);
          upsert({
            refetchQueries: [DCR_POPOVER_QUERY, PersonConsentField_DATA_QUERY],
            variables: {
              type: information.contactType.enum,
              entityType: information.entityType,
              entityAffiliationId: information.entityAffiliationId,
              values: dynamicList.list.map((value, index) => ({
                valueId: value.id > 0 ? value.id : null,
                value: inputValues[index] ?? '',
                categoryCode: value.category.code,
                consentStatus: value.consent?.status,
              })),
            },
          }).finally(() => {
            endEditing();
            setLoading(false);
            options.refetchData();
          });
        };

        function isCategoryAllowed(
          category: ContactFieldCategoriesQuery['contactCategories']['nodes']['0'],
          current?: Value
        ) {
          if (category.allowMultipleValues) return true;
          if (current?.category.code === category.code) return true;
          return (
            dynamicList.list.filter((v) => v.category.code === category.code)
              .length === 0
          );
        }

        const add = () => {
          setStateInputValues(inputValues);
          const index = dynamicList.list.length + 1;

          const newItem: Value = {
            id: -1 * index, // We will have this rule, where negative numbers are new records
            value: '',
            category: data!.contactCategories.nodes.find((cc) => isCategoryAllowed(cc))!,
            consent: {
              id: -1,
              status: false,
            },
          };
          dynamicList.insert(index, newItem);
        };

        function checkIfWillCreateDcr(): boolean {
          for (let i = 0; i < information.values.length; i++) {
            const value = information.values[i]!;
            // If the selected category is not mapped, we will just ignore
            if (
              value.category.isMapped 
              && record.maintainerSourceCode !== 'LOCAL'
            ) {
              const updatedValue = dynamicList.list.filter(
                (v) => v.id === value.id
              )[0];
              if (!updatedValue) return true; // This means we have removed a mapped value
              if (value.value !== updatedValue.value) return true;
            }
          }

          for (let i = 0; i < dynamicList.list.length; i++) {
            const value = dynamicList.list[i]!;
            // Negative ID means it's a new value.
            if (value.id < 0 && value.category.isMapped && isApbMaintained) return true;
          }
          // All checks passed
          return false;
        }

        const onConsentChange = (item: Value) => (event: CheckboxChangeEvent) => {
          const listIndex = dynamicList.list.findIndex((i) => i === item);
          dynamicList.replace(listIndex, {
            ...item,
            consent: {
              ...(item.consent || {
              // MARK: Building whole object, but we are only using the "status" field.
                __typename: 'ConsentContactValue',
                id: -1,
                contactValueId: item.id,
                status: event.target.checked,
                userId: -1,
                ct: null,
                ut: null,
              }),
              status: event.target.checked,
            },
          });
        };

        const willCreateDcrs = checkIfWillCreateDcr();

        const canAddMoreValues = data?.contactCategories.nodes?.reduce(
          (curr, acc) => curr || isCategoryAllowed(acc),
          false
        );

        return (
          <Modal
            open={visible}
            onCancel={close}
            closable={false}
            footer={false}
            width="clamp(320px, 75%, 680px)"
            wrapClassName="contact-attribute-field-modal-container"
          >
            <DraggableTable<Value>
              className="contact-attribute-field-table"
              showSortLabel
              pagination={false}
              dynamicList={dynamicList}
              footer={() => (
                <Button
                  onClick={add}
                  className="add_new_value"
                  disabled={!canAddMoreValues}
                >
                  {localization.formatMessage(Locale.Command.Add_new_value)}
                </Button>
              )}
              columns={[
                {
                  title: localization.formatMessage(Locale.General.Category),
                  hide: data && (data.contactCategories.nodes.length <= 1),
                  render: (_, item) => (
                    <Select
                      value={item.category.code}
                      style={{ width: '100%' }}
                      size="middle"
                      onChange={(newCategoryCode) => dynamicList.replace(
                        dynamicList.list.findIndex((i) => i === item),
                        {
                          ...item,
                          category: data!.contactCategories!.nodes.filter(
                            (c) => c.code === newCategoryCode
                          )[0]!,
                        }
                      )}
                    >
                      {(data?.contactCategories.nodes ?? []).map((category) => (
                        <Select.Option
                          key={category.code}
                          value={category.code}
                          disabled={!isCategoryAllowed(category, item)}
                        >
                          {category.heading}
                        </Select.Option>
                      ))}
                    </Select>
                  ),
                },
                {
                  dataIndex: 'value',
                  title: localization.formatMessage(Locale.General.Value),
                  width: 300,
                  render: (_, item, index) => {
                    const isPhoneOrMobile = (value: string) => value == '1' 
                    || value == '3' 
                    || value == '6'
                    || value === 'HCP_MOBILE' 
                    || value === 'LINK_MOBILE';

                    inputValues[index] = inputValues[index] || item.value || '';
                    return (
                      <CopyableInput
                        onChange={(e) => {
                          inputValues[index] = e.target.value;
                        }}
                        onPhoneChange={(phone) => {
                          inputValues[index] = phone;
                        }}
                        defaultValue={inputValues[index]}
                        onCopyContent={(func) => {
                          navigator.clipboard
                            .writeText(inputValues[index] ?? '')
                            .then(() => func());
                        }}
                        isPhoneNumber={isPhoneOrMobile(item.category.code)}
                        countryCode={countryCode}
                      />
                    );
                  },
                },
                {
                  dataIndex: 'consent',
                  title: localization.formatMessage(Locale.General.Consent),
                  hide: information.contactType.enum !== ContactTypeEnum.EMAIL,
                  render: (_, item) => (
                    <Space size="large">
                      {item.category.typeEnum === ContactTypeEnum.EMAIL && (
                        <Checkbox
                          checked={item.consent?.status}
                          onChange={onConsentChange(item)}
                        />
                      )}
                    </Space>
                  ),
                },
                {
                  render: (_, item, index) => (
                    <Space size="large">
                      <Popconfirm
                        title={localization.formatMessage(
                          Locale.Text.Confirm_remove_contact_entry
                        )}
                        okText={localization.formatMessage(Locale.General.Yes)}
                        cancelText={localization.formatMessage(
                          Locale.Command.Cancel
                        )}
                        onConfirm={() => {
                          inputValues.splice(index, 1);
                          setStateInputValues(inputValues);
                          dynamicList.remove(
                            dynamicList.list.findIndex((i) => i === item)
                          );
                        }}
                      >
                        <Button danger type="primary">
                          {localization.formatMessage(Locale.Command.Remove)}
                        </Button>
                      </Popconfirm>
                      {item.category.isMapped && isApbMaintained && (
                        <Tooltip
                          title={localization.formatMessage(
                            Locale.Text.Value_being_maintained_by,
                            { maintainer: 'aPureBase' }
                          )}
                        >
                          <SyncOutlined />
                        </Tooltip>
                      )}
                    </Space>
                  ),
                },
              ]}
            />
            <br />
            <Space style={{ width: '100%', justifyContent: 'center' }}>
              <Button onClick={close} className="cancel">
                {localization.formatMessage(Locale.Command.Cancel)}
              </Button>
              {willCreateDcrs ? (
                <Popconfirm
                  key="2"
                  title={localization.formatMessage(
                    Locale.Text.Create_dcr_verification
                  )}
                  onConfirm={onSave}
                  overlayClassName="confirm-create-dcr-popover"
                >
                  <Button
                    type="primary"
                    loading={loading}
                    className="save-button"
                    data-will-create-dcr="yes"
                  >
                    {localization.formatMessage(Locale.Command.Save)}
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  key="2"
                  type="primary"
                  className="save-button"
                  data-will-create-dcr="no"
                  loading={loading}
                  onClick={onSave}
                >
                  {localization.formatMessage(Locale.Command.Save)}
                </Button>
              )}
            </Space>
          </Modal>
        );
    },
  })
);

const TYPES_QUERY = gql`
  query EntityContactTypesQuery($entityType: EntityTypeEnum!) {
    contactTypes(criteria: { entityType: $entityType }) {
      hash
      nodes {
        code
        label: heading
      }
    }
  }
`;

gql`
  query ContactFieldCategories(
    $type: ContactTypeEnum!
    $entityType: EntityTypeEnum!
  ) {
    contactCategories(
      criteria: { contactType: $type, entityType: $entityType }
    ) {
      hash
      nodes {
        code
        heading
        isHeadingKey
        allowMultipleValues
        isMapped
        typeEnum
      }
    }
  }
`;

gql`
  mutation UpsertContactValuesMutation(
    $type: ContactTypeEnum!
    $entityType: EntityTypeEnum!
    $entityAffiliationId: Int!
    $values: [ContactValueInput!]!
  ) {
    upsertContactValues(
      type: $type
      entityType: $entityType
      entityAffiliationId: $entityAffiliationId
      values: $values
    ) {
      id
      value
    }
  }
`;
