/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { Form } from 'antd';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { FormInstance } from 'antd/lib/form/Form';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import Context from './EntitiesSearchContext';
import Top from './top/EntitiesSearchTop';
import Results, { EntitySearchRowIndicator } from './results/EntitiesSearchResults';
import { SupportedEntitySearchTypes, useEntitiesSearchQuery } from '../../browse/search_old/types';
import EntitiesSearchInitialization from './EntitiesSearchInitialization';
import { TableActionItem } from '../Table/Actions';
import { CriteriaBuilderHiddenFilter } from '../../util/criteriaBuilderSelector';
import { EntitiesSearchQuickActionItem } from './top/EntitiesSearchQuickActionItemType';
import { serializeCriteriaInput } from './EntitiesSearchUrlUtils';
import { AnyValueType, StateArray } from '../../util/StateArrayType';
import { useBroadcastStorage } from '../../util/useBroadcastStorage';
import { generateTableColumnsSortingKey, orderingFiltering, SortingStorage } from './results/useTableColumns';
import { useLocalization } from '../../util/useLocalization';
import { CriteriaInputFactory_FRAGMENT } from '../CriteriaInput/CriteriaInputQueries';
import {
  DefaultCriteriasQueryQuery,
  DefaultCriteriasQueryQueryVariables,
  MySimpleSavedSearchesQuery, PersonInput, SiteInput
} from '../../../gql/typings';
import { useSystemCountriesState } from '../../util/useSystemCountriesState';
import { useCriteria } from './useEntitiesSearchCriteria';
import { TableRowSelectionReturnProps, useTableRowSelection } from '../Table/useTableRowSelection';
import { PersonInputCodeMap } from '../../browse/person/create/CreatePerson';
import { SiteInputCodeMap } from '../../browse/site/create/CreateSite';
import { FlagCountry } from '../Flag/Flag';


export const EntitiesSearchContext = Context;

export type EntitiesSearchProps = {
  form?: FormInstance;
  entityType: SupportedEntitySearchTypes;
  globalSupport?: boolean;
  globalSearch?: boolean;
  globalState?: false|StateArray<boolean>;
  includeInactiveRecords?: false|StateArray<boolean>;
  includeUnplacedRecords?: false|StateArray<boolean>;
  includeOptedOutRecords?: false|StateArray<boolean>;
  tableSelection?: TableRowSelectionReturnProps;
  actions?: Array<TableActionItem>;
  quickActions?: Array<EntitiesSearchQuickActionItem>|null;
  defaultCriterias?: {
    criteriaCode: string;
    value: AnyValueType;
    disabled?: boolean;
  }[]|null;
  urlSearchEnabled?: boolean;

  /**
   * This will add extra hidden criteria filter on top of the criteria search.
   * These do not use the dynamicSearch, but rather just the standard CriteriaSearch.
   */
  criteriaFilter?: CriteriaBuilderHiddenFilter;

  /**
   * When able it will open links within the table in a new table instead of in the same tab.
   */
  openInNewTab?: boolean;
  rowIndicator?: EntitySearchRowIndicator;
  updateForRefresh?: number;
  children?: React.ReactNode;

  onVisibleKeysChange?: (keys: React.Key[]) => void;

  onDataChange?: (data: {
    totalCount?: number;
  }) => void;
  onSelectedCountriesChange?: (selectedCountries: string[]|undefined) => void;
  tableSize?: SizeType;
  onCreateEntityTab?: () => void;
  showCreateEntityButton?: boolean;
  currentSelectedCountry?: FlagCountry;
  customSearchFilterCriteria?: React.ReactElement;
  // eslint-disable-next-line react/no-unused-prop-types
  syncChanges?: boolean; // this is being used in EntitiesSearchInitialisation
  onlyCherryPicked?: false|StateArray<boolean>;
};

export type EntitiesSearchRef = {
  refetch: () => void;
  clearCriterias: () => void;
  formValues: PersonInput;
};

export const EntitiesSearch = forwardRef<EntitiesSearchRef, EntitiesSearchProps>(({
  form = Form.useForm()[0],
  entityType,
  globalSupport = false,
  globalSearch,
  globalState = globalSupport && useState<boolean>(false),
  includeInactiveRecords = useState<boolean>(false),
  includeUnplacedRecords = useState<boolean>(false),
  includeOptedOutRecords = useState<boolean>(false),
  tableSelection = useTableRowSelection({ entityType }),
  actions,
  quickActions,
  urlSearchEnabled = false,
  openInNewTab,
  rowIndicator,
  defaultCriterias = null,
  updateForRefresh,
  criteriaFilter,
  onVisibleKeysChange,
  onDataChange,
  onSelectedCountriesChange,
  tableSize,
  onCreateEntityTab,
  showCreateEntityButton,
  currentSelectedCountry,
  customSearchFilterCriteria,
  children,
  onlyCherryPicked = useState<boolean>(false),
}, ref) => {
  const [formValues, setFormValues] = useState(() => form.getFieldsValue());
  const [syncCriteria, setSyncCriteria] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const localization = useLocalization();
  const [disabledCriteriaIds, setDisabledCriteriaIds] = useState<number[]>([]);
  const countrySelect = useState<FlagCountry[] | undefined>(currentSelectedCountry ? [currentSelectedCountry] : undefined);
  // const [isIncludeInactiveRecords] = includeInactiveRecords || [false];
  const { data: criterias, loading } = useQuery<DefaultCriteriasQueryQuery>(
    DEFAULT_CRITERIAS_DATA_QUERY, { variables: { entityType } },
  );
  const [
    selectedCriterias,
    setSelectedCriterias,
  ] = useState<NonNullable<DefaultCriteriasQueryQuery['defaultCriterias']>['nodes']['0']['criteria'][]>([]);
  const [
    selectedSaved,
    setSelectedSaved,
  ] = useState<null | NonNullable<MySimpleSavedSearchesQuery['viewer']>['savedCriterias']['nodes']['0']>(null);
  const [ordering] = useBroadcastStorage<SortingStorage[]>(
    generateTableColumnsSortingKey(entityType),
    [],
    orderingFiltering(entityType, navigate, localization)
  );
  const selectedCountriesState = countrySelect[0] ? countrySelect :useSystemCountriesState();


  const criteria = useCriteria({
    formValues,
    ordering,
    pageState: tableSelection.tableProps.pageState,
    perPage: tableSelection.tableProps.perPageState[0],
    globalState,
    includeInactiveRecords,
    includeUnplacedRecords,
    includeOptedOutRecords,
    selectedCountriesState,
    entityType,
    criteriaFilter,
    onlyCherryPicked
  });

  const data = useEntitiesSearchQuery(entityType, criteria, openInNewTab, globalSearch);

  const apolloClient = useApolloClient();

  useEffect(() => {
    if (criterias) {
      setSelectedCriterias(criterias.defaultCriterias.nodes.map(dc => dc.criteria));
      setSyncCriteria(prev => !prev);
    }
  }, [criterias, loading]);
  useEffect(() => {
    onSelectedCountriesChange?.(selectedCountriesState[0]);
  }, [onSelectedCountriesChange, selectedCountriesState[0]]);

  useEffect(() => {
    onVisibleKeysChange?.(data?.data?.connection?.nodes?.map((k: { id: number }) => k.id) ?? []);
  }, [data]);

  const doSearch = () => {
    setFormValues(form?.getFieldsValue());
  };

  useEffect(() => {
    if (urlSearchEnabled) {
      const serialized = queryString.stringify(serializeCriteriaInput(apolloClient, criteria.criteria.criterias));
      const newUrl = `${location.pathname}?${serialized}`;
      if (serialized && `${location.pathname}${location.search}` !== newUrl) {
        navigate(newUrl);
      }
    } else {
      const newValues = form.getFieldsValue();
      if (!isEqual(formValues, newValues)) setFormValues(form.getFieldsValue());
    }
  }, [formValues]);

  const doClear = () => {
    form.resetFields();
    navigate(location.pathname);
    const data = apolloClient.readQuery<DefaultCriteriasQueryQuery, DefaultCriteriasQueryQueryVariables>({
      query: DEFAULT_CRITERIAS_DATA_QUERY,
      variables: { entityType },
    });
    setSelectedCriterias(data?.defaultCriterias.nodes.map(n => n.criteria) ?? []);
    if (globalState && globalState[1]) globalState[1](false);
  };

  const formatValues = (fValues: object): PersonInput | SiteInput => Object.entries(fValues).reduce((acc, cur) => {

    const criteriaCode = cur[0].split('-')[0]?.split('ci')[1];
    if (!criteriaCode) return acc;
    // @ts-ignore
    const criteriaInputCode = apolloClient.cache.data.data[`Criteria:${criteriaCode}`].code;
    if (!criteriaInputCode) return acc;
    if (entityType === 'PERSON') {
      if (!Object.prototype.hasOwnProperty.call(PersonInputCodeMap, criteriaInputCode)) return acc;
      // @ts-ignore
      return { ...acc, [PersonInputCodeMap[criteriaInputCode]]: cur[1] };
    }
    if (entityType === 'SITE') {
      if (!Object.prototype.hasOwnProperty.call(SiteInputCodeMap, criteriaInputCode)) return acc;
      // @ts-ignore
      return { ...acc, [SiteInputCodeMap[criteriaInputCode]]: cur[1] };
    }
    return acc;
  }, {}) as PersonInput;

  const doRefetch = () => {
    doSearch();
    setSelectedSaved(null);
  };

  useImperativeHandle(ref, () => ({
    refetch: doRefetch,
    clearCriterias: doClear,
    formValues: formatValues(formValues)
  }) as EntitiesSearchRef, [formValues]);


  return (
    <Context.Provider
      value={{
        selectedAdvanceCriterias: [],
        searchType: 'SIMPLE',
        entityType,
        selectedCriterias,
        setSelectedCriterias,
        globalState,
        tableSelection,
        form,
        doSearch,
        doClear,
        data,
        actions,
        quickActions,
        includeInactiveRecords,
        selectedSaved,
        setSelectedSaved,
        disabledCriteriaIds,
        setDisabledCriteriaIds,
        defaultCriterias,
        urlSearchEnabled,
        openInNewTab,
        includeUnplacedRecords,
        includeOptedOutRecords,
        selectedCountriesState,
        tableSize,
        onCreateEntityTab,
        showCreateEntityButton,
        onlyCherryPicked
      }}
    >
      <div className="entities-search-index-container">
        <EntitiesSearchInitialization
          updateForRefresh={updateForRefresh}
          onDataChange={onDataChange}
          syncChanges={syncCriteria}
        />
        <Top aPureBaseIds={customSearchFilterCriteria} />
        {children ?? (
          <Results rowIndicator={rowIndicator} onRowClick={data.onRowClick} />
        )}
      </div>
    </Context.Provider>
  );
});


export const DEFAULT_CRITERIAS_DATA_QUERY = gql`
  query DefaultCriteriasQuery($entityType: EntityTypeEnum!) {
    defaultCriterias(criteria: { entityType: $entityType}) {
      hash
      nodes {
        id
        criteria {
          id
          ...CriteriaInputFragment
        }
      }
    }
  }
  ${CriteriaInputFactory_FRAGMENT}
`;
