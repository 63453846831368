import React, { useEffect, useState } from 'react';
import { AutoComplete, DatePicker, Form, Input, Switch } from 'antd';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import {
  AddressInput,
  EntityTypeEnum,
  PersonInput,
  SuggestedStandardTitleQueryQuery,
  SuggestedStandardTitleQueryQueryVariables
} from '../../../../gql/typings';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import CreateAddAddressModal from './CreateAddAddressModal';
import CountryPicker from '../../../components/Input/Fields/CountryPicker';
import { useSystemCountriesState } from '../../../util/useSystemCountriesState';
import { FlagCountry } from '../../../components/Flag/Flag';
import SelectGender from '../../../components/Input/Fields/SelectGender';
import SelectPersonType from '../../../components/Input/Fields/SelectPersonType';
import ContactFormInput from '../Components/ContactFormInput';
import ExternalIdFormInput from '../Components/ExternalIdFormInput';
import EducationCodesFormInput from '../Components/EducationCodesFormInput';
import { DATE_FORMAT } from '../../../util/format';
import { useBroadcastStorage } from '../../../util/useBroadcastStorage';

type CreatePersonFieldProps = {
  keyCodes?: string[];
};

export const createPersonFields: Record<keyof Omit<PersonInput, 'workplace'>, React.FC<CreatePersonFieldProps>> = {
  // maintainerSource: () => <CreateRecordMaintainerSelectorFormField />,
  firstName: () => {
    const localization = useLocalization();
    return (<Form.Item
      name="firstName"
      rules={[{ required: true }]}
      label={localization.formatMessage(Locale.Attribute.First_name)}
    >
      <Input
        id="hcp-firstName"
        autoComplete="newpassword"
        placeholder={localization.formatMessage(Locale.Command.Enter_First_Name)}
      />
    </Form.Item>);
  },
  middleName: () => {
    const localization = useLocalization();
    return (
      <Form.Item
        name="middleName"
        label={localization.formatMessage(Locale.Attribute.Middle_name)}
      >
        <Input
          id="hcp-middleName"
          autoComplete="newpassword"
          placeholder={localization.formatMessage(Locale.Command.Enter_Middle_Name)}
        />
      </Form.Item>
    );
  },
  lastName: () => {
    const localization = useLocalization();
    return (
      <Form.Item
        name="lastName"
        rules={[{ required: true }]}
        label={localization.formatMessage(Locale.Attribute.Last_name)}
      >
        <Input
          id="hcp-lastName"
          autoComplete="newpassword"
          placeholder={localization.formatMessage(Locale.Command.Enter_Last_Name)}
        />
      </Form.Item>
    );
  },
  countryCode: () => {
    const localization = useLocalization();
    const [systemCountries] = useSystemCountriesState();
    return (
      <Form.Item
        name="countryCode"
        rules={[{ required: true }]}
        label={localization.formatMessage(Locale.Attribute.Country)}
        initialValue={systemCountries?.[0]}
      >
        <CountryPicker />
      </Form.Item>
    );
  },
  sex: () => {
    const localization = useLocalization();
    return (
      <Form.Item name="sex" label={localization.formatMessage(Locale.Attribute.Sex)}>
        <SelectGender />
      </Form.Item>

    );
  },
  birth: () => {
    const localization = useLocalization();
    return (
      <Form.Item name="birth" label={localization.formatMessage(Locale.Attribute.Birth)}>
        <DatePicker
          format={DATE_FORMAT}
          placeholder={localization.formatMessage(Locale.Command.Select_date)}
          style={{ width: '100%' }}
          disabledDate={date => dayjs().isBefore(date)}
        />
      </Form.Item>
    );
  },
  examYear: () => {
    const localization = useLocalization();
    return (
      <Form.Item name="examYear" label={localization.formatMessage(Locale.Attribute.Exam_year)}>
        <DatePicker
          picker="year"
          style={{ width: '100%' }}
          disabledDate={date => dayjs().isBefore(date)}
          placeholder={localization.formatMessage(Locale.Command.Select_Year)}
        />
      </Form.Item>
    );
  },
  title: () => {
    const localization = useLocalization();
    const [countryCode, setCountryCode] = useState<FlagCountry>();
    const {
      data, refetch,
    } = useQuery<SuggestedStandardTitleQueryQuery, SuggestedStandardTitleQueryQueryVariables>(SUGGESTED_STANDARD_TITLE_QUERY);

    useEffect(() => {
      refetch({ countryCode });
    }, [countryCode, refetch]);

    return (
      <Form.Item noStyle shouldUpdate={(prev: PersonInput, curr: PersonInput) => prev.countryCode !== curr.countryCode}>
        {({ getFieldValue }) => {
          const k: keyof PersonInput = 'countryCode';
          const currCountry = getFieldValue(k);

          if (currCountry !== countryCode) Promise.resolve().then(() => setCountryCode(currCountry));


          return (
            <Form.Item name="title" label={localization.formatMessage(Locale.Attribute.Title)}>
              <AutoComplete
                style={{ width: '100%' }}
                id="hcp-title"
                options={(data?.standardTitles?.nodes ?? []).map(it => ({
                  value: it.label
                }))}
                placeholder={localization.formatMessage(Locale.Command.Enter_title)}
                autoFocus={false}
                dropdownStyle={{ width: 400, maxWidth: 600 }}
                filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  },
  persTypeCode: () => {
    const localization = useLocalization();
    return (
      <Form.Item
        label={localization.formatMessage(Locale.Attribute.Person_type)}
        shouldUpdate={(prevValues, curValues) => prevValues.countryCode !== curValues.countryCode}
      >
        {({ getFieldValue }) => (
          <Form.Item name="persTypeCode" noStyle>
            <SelectPersonType countryCode={getFieldValue('countryCode')} />
          </Form.Item>
        )}
      </Form.Item>
    );
  },
  contacts: ({ keyCodes = [] }) => {
    const localization = useLocalization();
    const formListInitialValue = keyCodes?.map(keyCode => ({ categoryCode: keyCode }));
    return (
      <Form.List name='contacts' initialValue={formListInitialValue}>
        {
          (fields) => fields?.map((field, index) => <Form.Item
            key={field.key}
            label={index === 0 ? localization.formatMessage(Locale.General.Contact_information): ' '}
            colon={index === 0}
            shouldUpdate={() => true}
          >
            {({ getFieldValue }) => (<ContactFormInput
              namePath={[field.name.toString()]}
              entityType={EntityTypeEnum.PERSON}
              contactValue={getFieldValue(['contacts', index])}
              countryCode={getFieldValue('countryCode')}
            />)}
          </Form.Item>)
        }
      </Form.List>
    );
  },
  addresses: () => {
    const localization = useLocalization();
    return (
      <Form.List name='addresses' initialValue={[null]}>
        {
          (fields) => fields.map(field => <Form.Item
            key={field.key}
            label={localization.formatMessage(Locale.Attribute.Address)}
            shouldUpdate={(prev, curr) => prev.countryCode !== curr.countryCode}
          >
            {({ getFieldValue }) => (
              <Form.Item name={field.name} noStyle>
                <CreateAddAddressModal
                  countryCode={getFieldValue('countryCode')}
                  entityType={EntityTypeEnum.PERSON}
                  usedAddressTypes={getFieldValue('addresses').map((x: AddressInput) => x?.addressTypeCode)}
                />
              </Form.Item>
            )}
          </Form.Item>)
        }
      </Form.List>
    );
  },
  notes: () => {
    const localization = useLocalization();
    return (
      <Form.Item name="notes" label={localization.formatMessage(Locale.Attribute.Note)}>
        <Input.TextArea
          id="hcp-notes"
          placeholder={localization.formatMessage(Locale.Command.Enter_Notes)}
        />
      </Form.Item>
    );
  },
  externalIds: ({ keyCodes }) => {
    const localization = useLocalization();
    const initialValue = keyCodes?.map(keyCode => ({ externalIdTypeCode: keyCode }));
    return (
      <Form.List name='externalIds' initialValue={initialValue}>
        {
          (fields) => fields?.map(field => <Form.Item
            key={field.key}
            label={localization.formatMessage(Locale.Attribute.External_ID)}
            shouldUpdate={() => true}
          >
            {() => (
              <ExternalIdFormInput
                namePath={[field.name.toString()]}
                entityType={EntityTypeEnum.PERSON}
              />
            )}
          </Form.Item>)
        }
      </Form.List>
    );
  },
  customFieldValues: () => <span>Currently not supported</span>,
  educationCodes: () => {
    const localization = useLocalization();
    return (
      <Form.Item
        label={localization.formatMessage(Locale.Attribute.Education)}
        shouldUpdate={(prev, curr) => prev.countryCode !== curr.countryCode}
      >
        {({ getFieldValue }) => (
          <Form.Item name='educationCodes' noStyle>
            <EducationCodesFormInput countryCode={getFieldValue('countryCode')} />
          </Form.Item>
        )}
      </Form.Item>
    );
  },
  instantValidation: () => {
    const localization = useLocalization();
    const [instantValidate, setInstantValidate] = useBroadcastStorage('instant-validation', true);
    return (
      <Form.Item
        name='instantValidation'
        label={localization.formatMessage(Locale.Attribute.Instant_Validation)}
        valuePropName={instantValidate ? 'checked': ''}
        initialValue={instantValidate}
      >
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={e => setInstantValidate(e)}
        />
      </Form.Item>
    );
  },
};

export const SUGGESTED_STANDARD_TITLE_QUERY = gql`
  query SuggestedStandardTitleQuery($countryCode: String) {
    standardTitles(criteria: {country: $countryCode, fetchSize: {limit: 1000}}) {
      hash
      nodes {
        id
        label
      }
    }
  }
`;
