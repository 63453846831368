import React, { AnchorHTMLAttributes } from 'react';
import { isEmpty } from 'lodash';
import { Tooltip, Typography } from 'antd';
import { Country } from 'react-phone-number-input/input';
import { Optional } from '../../util/StateArrayType';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import { ContactTypeEnum } from '../../../gql/typings';
import { formatPhoneByCode } from '../../util/format';

type ContactLinkProps = {
  value: Optional<string>;
  type: ContactTypeEnum;
  categoryName?: string;
  consent?: boolean;
  countryCode? : Country;
};

const hrefTransformer: Record<ContactTypeEnum, (url: string) => Omit<AnchorHTMLAttributes<unknown>, 'type'>> = {
  [ContactTypeEnum.FAX]: url => ({ href: `fax:${url}` }),
  [ContactTypeEnum.EMAIL]: url => ({ href: `mailto:${url}` }),
  [ContactTypeEnum.PHONE]: url => ({ href: `tel:${url}` }),
  [ContactTypeEnum.WEBSITE]: url => {
    const href = url.toLowerCase().startsWith('http:') || url.toLowerCase().startsWith('https:')
      ? url
      : `http://${url}`;
    return {
      href,
      target: '_blank',
      className: 'consent',
    };
  },
};

const ContactLink: React.FC<ContactLinkProps> = ({
  value,
  type,
  categoryName,
  consent,
  countryCode
}) => {
  if (!value || isEmpty(value)) return <></>;
  const localization = useLocalization();
  const tooltip = consent ? localization.formatMessage(Locale.Text.Contact_has_consent) : '';
  const formattedValue = type === ContactTypeEnum.PHONE ? formatPhoneByCode(value, countryCode) : value;

  return (
    <Tooltip title={tooltip}>
      <Typography.Link
        style={{ marginBottom: 0 }}
        ellipsis
        className={consent ? 'has-consent' : 'link'}
        {...hrefTransformer[type](value)}
      >
        {formattedValue}
      </Typography.Link>
      {categoryName && <span>&nbsp;({categoryName})</span>}
    </Tooltip>
  );
};

export default ContactLink;
