import { Country, formatPhoneNumberIntl } from 'react-phone-number-input/input';
import { getCountryCallingCode } from 'react-phone-number-input';
import { Optional } from './StateArrayType';

type AddressProps = {
  street?: Optional<string>;
  postalCode?: Optional<string>;
  city?: Optional<string>;
  countryCode?: Optional<string>;
};

export const formatAddress = ({
  street,
  postalCode,
  city,
  countryCode,

}: AddressProps) => [street, postalCode, city, countryCode].filter(e => e).join(', ');

export const formatPhoneByCode = (phoneNumber: string | number, countryCode?: Country) => {
  if (!countryCode || phoneNumber.toString().includes('+')) return formatPhoneNumberIntl(phoneNumber as string);
  const callingCode = getCountryCallingCode(countryCode);
  const phoneNumberWithCallingCode = `+${callingCode}${phoneNumber}`;
  return formatPhoneNumberIntl(phoneNumberWithCallingCode);
};

export const DATE_FORMAT = 'DD-MM-YYYY';

export const DATE_FORMAT_DAY = 'dddd DD-MM-YYYY';

export const DATE_FORMAT_TIME_DAY = 'dddd DD-MM-YYYY hh:mm a';

export const DATE_FORMAT_TIME = 'DD-MM-YYYY hh:mm a';
